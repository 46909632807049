import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../crud/CrudForm'
import {
    disabledaysService as service,
    companyService,
} from '../../../services'
import { useTranslation } from 'react-i18next'

const initElement = {
    day: null,
    name: null,
    user: { id: null, name: '' },
    company: { id: null, name: '' },
}

export default function FormCompanyDisabledays(props) {
    const [objectform, setObjectform] = React.useState({ ...initElement })
    type FormParms = {
        id: string
        company: string
    }
    let { id, company } = useParams<FormParms>()
    const [idRow, setIdrow] = useState(id ? Number(id) : 0)
    const { t, i18n } = useTranslation()
    const [object, setObject] = useState({
        ...initElement,
        ['company']: { id: company, name: '' },
    })

    const estructureform = [
        {
            type: 'autocomplete',
            name: 'company.id',
            service: companyService.combo,
            col: 4,
            readonly: true,
        },
        {
            type: 'input',
            name: 'day',
            inputtype: 'date',
            col: 4,
        },
        {
            type: 'input',
            name: 'name',
            col: 4,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit = element
        return objectedit
    }

    const isValid = function (object) {
        return null
    }

    return (
        <>
            <CrudForm
                objectform={{
                    ...object,
                }}
                estructureform={estructureform}
                i18n={'disableday'}
                urledit={'/company/edit/' + company + '/disableday/edit/'}
                urlCancel={'/company/edit/' + company}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
            />
        </>
    )
}
