import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import CrudTab from '../../crud/CrudTab'
import { timetableService as service } from '../../../services'
import PrintIcon from '@mui/icons-material/Print'
import 'toasted-notes/src/styles.css'
import { useTranslation } from 'react-i18next'
import { ToastContainer, toast } from 'react-toastify'
import Grid from '@mui/material/Grid'

export default function TabTimetableUser(props) {
    const [filter, setFilter] = useState({
        car: null,
        company: props.idcompany,
        user: props.iduser,
        cartype: null,
    })

    const { t } = useTranslation()

    useEffect(() => {}, [])

    useEffect(() => {
        setFilter({
            car: null,
            company: props.idcompany,
            user: props.iduser,
            cartype: null,
        })
    }, [props.idcompany, props.iduser])

    const paintCell = {
        day: (value, object) => {
            if (object['day'] && object['day'] > 0) {
                if (object['day'] === 1) {
                    return <span>Domingo</span>
                } else if (object['day'] === 2) {
                    return <span>Lunes</span>
                } else if (object['day'] === 3) {
                    return <span>Martes</span>
                } else if (object['day'] === 4) {
                    return <span>Miercoles</span>
                } else if (object['day'] === 5) {
                    return <span>Jueves</span>
                } else if (object['day'] === 6) {
                    return <span>Viernes</span>
                } else if (object['day'] === 7) {
                    return <span>Sabado</span>
                }
            } else if (object['allDays']) {
                return <span>Todos los días</span>
            } else if (object['weekend']) {
                return <span>Fin de semana</span>
            } else if (object['workingDays']) {
                return <span>De lunes a viernes</span>
            } else {
                return <span> --</span>
            }
        },
    }

    return (
        <>
            <CrudTab
                hiddenFilter={true}
                service={service}
                newbtn={true}
                titlebutton={'Nueva franja horaría'}
                urlnew={
                    '/company/edit/' +
                    props.idcompany +
                    '/timetable/user/' +
                    props.iduser +
                    '/' +
                    props.userc +
                    '/new/'
                }
                urledit={
                    '/company/edit/' +
                    props.idcompany +
                    '/timetable/user/' +
                    props.iduser +
                    '/' +
                    props.userc +
                    '/edit/'
                }
                filter={filter}
                i18n={'timetable'}
                fieldstable={[
                    'day',
                    'hourStartMorning',
                    'endStarMorning',
                    'hourStartAfternoon',
                    'endStarAfternoon',
                ]}
                labels={{ 'day|allDays|weekend|workingDays': 'Día' }}
                paintCell={paintCell}
                haveother={false}
                deleteaction={true}
                selectable={false}
            />
            <ToastContainer />
        </>
    )
}
