import { useEffect, useState } from 'react'
import { Client, StompHeaders } from '@stomp/stompjs'

export const WebscoketMobify = (props) => {
    const [stompClient, setStompClient] = useState<any>(null)

    const authHeader = () => {
        let token = localStorage.getItem('token')

        if (token) {
            return 'Bearer ' + token
        } else {
            return ''
        }
    }

    useEffect(() => {
        const stompConfig = {
            connectHeaders: { Authorization: authHeader() },
            brokerURL: process.env.REACT_APP_HOST_WS!,
            debug: function (str) {
                console.log('STOMP: ' + str)
            },
            // If disconnected, it will retry after 200ms
            reconnectDelay: 200,
            // Subscriptions should be done inside onConnect as those need to reinstated when the broker reconnects
            onConnect: (frame) => {
                stompClient!.subscribe(
                    props.channel,
                    (evnt) => {
                        console.log('LLEGA MENSAJE ==>' + evnt.body)
                        if (props.callback) {
                            props.callback(evnt.body)
                        }
                    },
                    {
                        Authorization: authHeader(),
                    }
                )
            },
        }
        const stompClient = new Client(stompConfig)
        setStompClient(stompClient!)
        stompClient.activate()

        return () => {
            stompClient.deactivate()
        }
    }, [])

    return <></>
}
