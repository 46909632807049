import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import CrudTab from '../../crud/CrudTab'
import { usercompaniesService as service } from '../../../services'
import PrintIcon from '@mui/icons-material/Print'
import 'toasted-notes/src/styles.css'
import { useTranslation } from 'react-i18next'
import { ToastContainer, toast } from 'react-toastify'
import Grid from '@mui/material/Grid'

export default function TabUsersCompany(props) {
    const [filter, setFilter] = useState({
        name: null,
        lastname: null,
        email: null,
        phone: null,
        company: props.idcompany,
        acceptByCompany: null,
        acceptByUser: null,
    })

    const { t } = useTranslation()

    useEffect(() => {}, [])

    useEffect(() => {
        setFilter({
            name: null,
            lastname: null,
            email: null,
            phone: null,
            company: props.idcompany,
            acceptByCompany: null,
            acceptByUser: null,
        })
    }, [props.idcompany])

    const paintCell = {
        'user.name': (value, object) => {
            return 'USER'
        },
    }

    return (
        <>
            <CrudTab
                hiddenFilter={true}
                service={service}
                newbtn={true}
                titlebutton={'Nuevo usuario'}
                urlnew={
                    '/company/edit/' + props.idcompany + '/usertocompany/new/'
                }
                urledit={
                    '/company/edit/' + props.idcompany + '/usertocompany/edit/'
                }
                filter={filter}
                i18n={'usertocompany'}
                fieldstable={[
                    'nifUser',
                    'user.name',
                    'acceptByCompany',
                    'acceptByUser',
                    'dateEnd',
                ]}
                paintCell={paintCell}
                haveother={false}
                deleteaction={true}
                selectable={false}
            />
            <ToastContainer />
        </>
    )
}
