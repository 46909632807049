import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import CrudTab from '../../crud/CrudTab'
import { bonouserService } from '../../../services'
import PrintIcon from '@mui/icons-material/Print'
import 'toasted-notes/src/styles.css'
import { useTranslation } from 'react-i18next'
import { ToastContainer, toast } from 'react-toastify'
import Grid from '@mui/material/Grid'
import Moment from 'react-moment'

export default function TabBono(props) {
    const [filter, setFilter] = useState({
        user: props.idclient,
        from: null,
        to: null,
    })

    const { t } = useTranslation()

    useEffect(() => {}, [])

    useEffect(() => {
        setFilter({
            user: props.idclient,
            from: null,
            to: null,
        })
    }, [props.idclient])

    const paintvalue = {
        createDate: (value, object) => {
            if (value) {
                return <Moment date={value} format={'DD/MM/yyyy HH:mm:ss'} />
            } else {
                return <></>
            }
        },
        endBono: (value, object) => {
            if (value) {
                return <Moment date={value} format={'DD/MM/yyyy HH:mm:ss'} />
            } else {
                return <></>
            }
        },
        money: (value, object) => {
            if (value) {
                return <>{(+value).toFixed(2)}€</>
            } else {
                return <>0.00€</>
            }
        },
        moneyActive: (value, object) => {
            if (value) {
                return <>{(+value).toFixed(2)}€</>
            } else {
                return <>0.00€</>
            }
        },
    }

    return (
        <>
            <CrudTab
                service={bonouserService}
                urledit={'/bonouser/view/'}
                deleteaction={false}
                editable={true}
                filter={filter}
                i18n={'bonouser'}
                fieldstable={[
                    'bono.name',
                    'createDate',
                    'endBono',
                    'money',
                    'moneyActive',
                ]}
                haveother={false}
                selectable={false}
                hiddenFilter={true}
                paintvalue={paintvalue}
            />
            <ToastContainer />
        </>
    )
}
