import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css'
import './language/i18n'
import { isNotLoginLogin } from './utils/session'
import LoginRoute from './components/route/LoginRoute'
import Login from './components/pages/login/Login'
import Dashboard from './components/pages/dasboard/Dashboard'
import PrivateRoute from './components/route/PrivateRoute'
import Administrator from './components/pages/users/Administrator'
import FormAdministrator from './components/pages/users/FormAdministrator'
import FormMyAccount from './components/pages/users/FormMyAccount'
import FormConfig from './components/pages/configuration/config/FormConfig'
import CarType from './components/pages/configuration/cartype/CarType'
import FormCarType from './components/pages/configuration/cartype/FormCarType'
import MarkType from './components/pages/configuration/marktype/MarkType'
import FormMarkType from './components/pages/configuration/marktype/FormMarkType'
import Prices from './components/pages/configuration/prices/Prices'
import FormPrices from './components/pages/configuration/prices/FormPrices'
import StateBill from './components/pages/configuration/statebill/StateBill'
import FormStatebill from './components/pages/configuration/statebill/FormStatebill'
import Device from './components/pages/devices/Devices'
import FormDevice from './components/pages/devices/FormDevice'
import Users from './components/pages/users/Users'
import FormUsers from './components/pages/users/FormUsers'
import Company from './components/pages/company/Company'
import FormCompany from './components/pages/company/FormCompany'
import Coupon from './components/pages/coupon/Coupon'
import FormCoupon from './components/pages/coupon/FormCoupon'
import FormCar from './components/pages/car/FormCar'
import Car from './components/pages/car/Car'
import Trip from './components/pages/trip/Trip'
import FormTrip from './components/pages/trip/FormTrip'
import Mark from './components/pages/mark/Mark'
import FormMark from './components/pages/mark/FormMark'
import Pack from './components/pages/pack/Pack'
import FormPack from './components/pages/pack/FormPack'
import Share from './components/pages/share/Share'
import Rent from './components/pages/rent/Rent'
import FormRent from './components/pages/rent/FormRent'
import Zone from './components/pages/zone/Zone'
import FormZone from './components/pages/zone/FormZone'
import FormShare from './components/pages/share/FormShare'
import Bill from './components/pages/bill/Bill'
import FormBill from './components/pages/bill/FormBill'
import Alerts from './components/pages/alerts/Alerts'
import FormAlert from './components/pages/alerts/FormAlert'
import DisableApp from './components/pages/migration/DisableApp'
import ForceUpdate from './components/pages/migration/ForceUpdate'
import Ads from './components/pages/ads/Ads'
import FormAds from './components/pages/ads/FormAds'
import Bono from './components/pages/bono/Bono'
import FormBono from './components/pages/bono/FormBono'
import Modelcar from './components/pages/configuration/modelcar/Modelcar'
import FormModelcar from './components/pages/configuration/modelcar/FormModelcar'
import RentHistory from './components/pages/rent/RentHistory'
import LocationRent from './components/pages/configuration/locationrent/LocationRent'
import FormLocationRent from './components/pages/configuration/locationrent/FormLocationRent'
import Userbusiness from './components/pages/users/Userbusiness'
import FormUserbusiness from './components/pages/users/FormUserbusiness'
import Suscription from './components/pages/suscription/Suscription'
import FormSuscription from './components/pages/suscription/FormSuscription'
import SuscriptionHistory from './components/pages/suscription/SuscriptionHistory'
import BonoUse from './components/pages/users/BonosUse'
import FormUsercompany from './components/pages/users/FormUsercompany'
import Usercompany from './components/pages/users/Usercompany'
import FormCompanyTabTimetable from './components/pages/company/FormCompanTimeTable'
import FormCompanyDisabledays from './components/pages/company/FormCompanyDisabledays'
import FormCompanyToUsers from './components/pages/company/FormCompanyToUsers'
import FormCompanyUserTimeTable from './components/pages/company/FormCompanyUserTimeTable'
import FormCompanyUserDisabledays from './components/pages/company/FormCompanyUserDisabledays'
import GenerateBillCompany from './components/pages/bill/GenerateBillCompany'

export default function App() {
    const isLoggedNotIn = isNotLoginLogin()

    return (
        <>
            <Router>
                <Routes>
                    <Route
                        path="/login"
                        element={
                            <LoginRoute>
                                <Login />
                            </LoginRoute>
                        }
                    />
                    <Route
                        path="/administrator/new"
                        element={
                            <PrivateRoute>
                                <FormAdministrator />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/administrator/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormAdministrator />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/administrator"
                        element={
                            <PrivateRoute>
                                <Administrator />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/dashboard"
                        element={
                            <PrivateRoute>
                                <Dashboard />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/myaccount/:id"
                        element={
                            <PrivateRoute>
                                <FormMyAccount />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/configuration"
                        element={
                            <PrivateRoute>
                                <FormConfig />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/cartype/new"
                        element={
                            <PrivateRoute>
                                <FormCarType />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/cartype/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormCarType />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/cartype"
                        element={
                            <PrivateRoute>
                                <CarType />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/marktype/new"
                        element={
                            <PrivateRoute>
                                <FormMarkType />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/marktype/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormMarkType />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/marktype"
                        element={
                            <PrivateRoute>
                                <MarkType />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/prices/new"
                        element={
                            <PrivateRoute>
                                <FormPrices />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/prices/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormPrices />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/prices"
                        element={
                            <PrivateRoute>
                                <Prices />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/statebill/new"
                        element={
                            <PrivateRoute>
                                <FormStatebill />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/statebill/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormStatebill />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/statebill"
                        element={
                            <PrivateRoute>
                                <StateBill />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/device/new"
                        element={
                            <PrivateRoute>
                                <FormDevice />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/device/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormDevice />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/device"
                        element={
                            <PrivateRoute>
                                <Device />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/users/new"
                        element={
                            <PrivateRoute>
                                <FormUsers />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/users/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormUsers />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/users"
                        element={
                            <PrivateRoute>
                                <Users />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/userbusiness/new"
                        element={
                            <PrivateRoute>
                                <FormUserbusiness />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/userbusiness/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormUserbusiness />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/userbusiness"
                        element={
                            <PrivateRoute>
                                <Userbusiness />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/usercompany/new"
                        element={
                            <PrivateRoute>
                                <FormUsercompany />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/usercompany/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormUsercompany />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/usercompany"
                        element={
                            <PrivateRoute>
                                <Usercompany />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/company/new"
                        element={
                            <PrivateRoute>
                                <FormCompany />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/company/edit/:company/timetable/user/:user/:userc/new/"
                        element={
                            <PrivateRoute>
                                <FormCompanyUserTimeTable />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/company/edit/:company/timetable/user/:user/:userc/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormCompanyUserTimeTable />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/company/edit/:company/timetable/new/"
                        element={
                            <PrivateRoute>
                                <FormCompanyTabTimetable />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/company/edit/:company/timetable/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormCompanyTabTimetable />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/company/edit/:company/disableday/user/:user/:userc/new/"
                        element={
                            <PrivateRoute>
                                <FormCompanyUserDisabledays />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/company/edit/:company/disableday/user/:user/:userc/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormCompanyUserDisabledays />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/company/edit/:company/disableday/new/"
                        element={
                            <PrivateRoute>
                                <FormCompanyDisabledays />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/company/edit/:company/disableday/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormCompanyDisabledays />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/company/edit/:company/usertocompany/new/"
                        element={
                            <PrivateRoute>
                                <FormCompanyToUsers />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/company/edit/:company/usertocompany/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormCompanyToUsers />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/company/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormCompany />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/company"
                        element={
                            <PrivateRoute>
                                <Company />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/coupon/new"
                        element={
                            <PrivateRoute>
                                <FormCoupon />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/coupon/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormCoupon />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/coupon"
                        element={
                            <PrivateRoute>
                                <Coupon />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/car/new"
                        element={
                            <PrivateRoute>
                                <FormCar />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/car/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormCar />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/car"
                        element={
                            <PrivateRoute>
                                <Car />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/trip/new"
                        element={
                            <PrivateRoute>
                                <FormTrip />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/trip/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormTrip />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/trip"
                        element={
                            <PrivateRoute>
                                <Trip />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/mark/new"
                        element={
                            <PrivateRoute>
                                <FormMark />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/mark/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormMark />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/mark"
                        element={
                            <PrivateRoute>
                                <Mark />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/pack/new"
                        element={
                            <PrivateRoute>
                                <FormPack />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/pack/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormPack />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/pack"
                        element={
                            <PrivateRoute>
                                <Pack />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/share/new"
                        element={
                            <PrivateRoute>
                                <Share />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/share/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormShare />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/share"
                        element={
                            <PrivateRoute>
                                <Share />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/rent/new"
                        element={
                            <PrivateRoute>
                                <FormRent />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/rent/history/:id"
                        element={
                            <PrivateRoute>
                                <RentHistory />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/rent/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormRent />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/rent"
                        element={
                            <PrivateRoute>
                                <Rent />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/suscription/new"
                        element={
                            <PrivateRoute>
                                <FormSuscription />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/suscription/history/:id"
                        element={
                            <PrivateRoute>
                                <SuscriptionHistory />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/suscription/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormSuscription />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/suscription"
                        element={
                            <PrivateRoute>
                                <Suscription />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/zone/new"
                        element={
                            <PrivateRoute>
                                <FormZone />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/zone/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormZone />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/zone"
                        element={
                            <PrivateRoute>
                                <Zone />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/bill/company"
                        element={
                            <PrivateRoute>
                                <GenerateBillCompany />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/bill/new"
                        element={
                            <PrivateRoute>
                                <FormBill />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/bill/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormBill />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/bill"
                        element={
                            <PrivateRoute>
                                <Bill />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/alerts/new"
                        element={
                            <PrivateRoute>
                                <FormAlert />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/alerts/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormAlert />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/alerts"
                        element={
                            <PrivateRoute>
                                <Alerts />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/ads/new"
                        element={
                            <PrivateRoute>
                                <FormAds />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/ads/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormAds />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/ads"
                        element={
                            <PrivateRoute>
                                <Ads />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/bono/new"
                        element={
                            <PrivateRoute>
                                <FormBono />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/bono/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormBono />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/bono"
                        element={
                            <PrivateRoute>
                                <Bono />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/modelcar/new"
                        element={
                            <PrivateRoute>
                                <FormModelcar />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/modelcar/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormModelcar />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/modelcar"
                        element={
                            <PrivateRoute>
                                <Modelcar />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/locationrent/new"
                        element={
                            <PrivateRoute>
                                <FormLocationRent />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/locationrent/edit/:id"
                        element={
                            <PrivateRoute>
                                <FormLocationRent />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/locationrent"
                        element={
                            <PrivateRoute>
                                <LocationRent />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/bonouser/view/:id"
                        element={
                            <PrivateRoute>
                                <BonoUse />
                            </PrivateRoute>
                        }
                    />
                    <Route path="/disableapp" element={<DisableApp />} />
                    <Route path="/forceupdate" element={<ForceUpdate />} />
                    {isLoggedNotIn ? (
                        <Route path="/" element={<Login />} />
                    ) : (
                        <Route
                            path="/"
                            element={
                                <PrivateRoute>
                                    <Dashboard />
                                </PrivateRoute>
                            }
                        />
                    )}
                </Routes>
            </Router>
        </>
    )
}
