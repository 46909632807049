import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../crud/CrudForm'
import {
    disabledaysService as service,
    companyService,
    userService,
} from '../../../services'
import { useTranslation } from 'react-i18next'

const initElement = {
    day: null,
    name: null,
    user: { id: null, name: '' },
    company: { id: null, name: '' },
}

export default function FormCompanyUserDisabledays(props) {
    const [objectform, setObjectform] = React.useState({ ...initElement })
    type FormParms = {
        id: string
        company: string
        user: string
        userc: string
    }
    let { id, company, user, userc } = useParams<FormParms>()
    const [idRow, setIdrow] = useState(id ? Number(id) : 0)
    const { t, i18n } = useTranslation()
    var aux = {
        company: { id: company, name: '' },
        user: { id: user, name: '' },
    }
    const [object, setObject] = useState({
        ...initElement,
        ...aux,
    })

    const estructureform = [
        {
            type: 'autocomplete',
            name: 'company.id',
            service: companyService.combo,
            col: 4,
            readonly: true,
        },
        {
            type: 'autocomplete',
            name: 'user.id',
            service: userService.combo,
            col: 4,
            readonly: true,
        },
        {
            type: 'input',
            name: 'day',
            inputtype: 'date',
            col: 4,
        },
        {
            type: 'input',
            name: 'name',
            col: 4,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit = element
        return objectedit
    }

    const isValid = function (object) {
        return null
    }

    return (
        <>
            <CrudForm
                objectform={{
                    ...object,
                }}
                estructureform={estructureform}
                i18n={'disableday'}
                urledit={
                    '/company/edit/' +
                    company +
                    '/disableday/user/' +
                    user +
                    '/' +
                    userc +
                    '/edit/'
                }
                urlCancel={
                    '/company/edit/' + company + '/usertocompany/edit/' + userc
                }
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
            />
        </>
    )
}
