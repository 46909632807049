import React, { useState, useEffect } from 'react'
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css'
import CrudForm from '../../../crud/CrudForm'
import { configurationService as service } from '../../../../services'

const initElement = {
    nif: null,
    email: null,
    phone: null,
    street: null,
    picture: null,
    name: null,
    serie: null,
    serieR: null,
    wrong_payment_email: null,
    admin_email: null,
    rental_email: null,
    app_contact_mail: null,
    app_contact_phone: null,
    politica: null,
    terminos: null,
    cookies: null,
    aviso: null,
    app_help: null,
    priceParkKm: null,
    hourBeforeRental: null,
    moneyFixed: null,
    bonificationFuel: null,
    numberInitBill: null,
    iva: null,
}

export default function FormConfig() {
    let id = 1
    const [idRow, setIdrow] = useState(id)

    const estructureform = [
        {
            type: 'input',
            name: 'minversionios',
            col: 4,
        },

        {
            type: 'input',
            name: 'minversionandroid',
            col: 4,
        },
        {
            type: 'input',
            name: 'nif',
            col: 4,
        },
        {
            type: 'input',
            name: 'email',
            col: 4,
        },
        {
            type: 'input',
            name: 'name',
            col: 4,
        },
        {
            type: 'input',
            name: 'phone',
            col: 4,
        },
        {
            type: 'input',
            name: 'street',
            col: 4,
        },
        {
            type: 'input',
            name: 'serie',
            col: 4,
        },
        {
            type: 'input',
            name: 'serieCompany',
            col: 4,
        },
        {
            type: 'input',
            name: 'serieR',
            col: 4,
        },
        {
            type: 'input',
            name: 'iva',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'wrong_payment_email',
            col: 4,
        },
        {
            type: 'input',
            name: 'admin_email',
            col: 4,
        },
        {
            type: 'input',
            name: 'rental_email',
            col: 4,
        },
        {
            type: 'input',
            name: 'app_contact_mail',
            col: 4,
        },
        {
            type: 'input',
            name: 'app_contact_phone',
            col: 4,
        },
        {
            type: 'input',
            name: 'priceParkKm',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'hourBeforeRental',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'moneyFixed',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'image',
            name: 'picture',
            col: 2,
        },
        {
            type: 'editor',
            name: 'politica',
            col: 12,
        },
        {
            type: 'editor',
            name: 'terminos',
            col: 12,
        },
        {
            type: 'editor',
            name: 'cookies',
            col: 12,
        },
        {
            type: 'editor',
            name: 'aviso',
            col: 12,
        },
        {
            type: 'editor',
            name: 'app_help',
            col: 12,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit = { ...objectedit, ...element }

        return objectedit
    }

    const isValid = function (object) {
        return null
    }

    return (
        <CrudForm
            objectform={{ ...initElement }}
            estructureform={estructureform}
            i18n={'config'}
            urledit={'/shopdata'}
            urlCancel={'/shopdata'}
            service={service}
            recoveryElement={recoveryElement}
            valid={isValid}
            haveother={true}
            id={idRow}
        />
    )
}
