import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import CrudEmpty from '../../crud/CrudEmpty'
import Filter from '../../filter/Filter'
import { useTranslation } from 'react-i18next'
import {
    companyService,
    statebillService,
    billService,
} from '../../../services'
import CustomPaginationActionsTable from '../../table/Table'
import {
    initElementStreet,
    estructureformStreet,
    getElementStreet,
    headerFecth,
} from '../../../utils/Data'
import {
    setValueAmout,
    setValuePrice,
    setValueIva,
    updateRows,
    updateDiscountTotal,
    setValueClientData,
    fieldIvas,
} from '../../../utils/UtilsBill'
import { Card, Grid } from '@mui/material'
import Form from '../../form/Form'
import { ToastContainer, toast } from 'react-toastify'

type GenerateBillCompanyParams = {
    bill: any
    company: number
    filter: { company: number }
    reload: boolean
    loading: boolean
    expanded: boolean
}

export default function GenerateBillCompany() {
    const initline = {
        id: -1,
        amount: 0.0,
        price: 0.0,
        percentIva: 21.0,
        iva: 0.0,
        total: 0.0,
        base: 0.0,
        discount: 0.0,
        discountPercent: 0.0,
    }

    const initElement = {
        client: { id: null, name: '' },
        number: null,
        date: null,
        state: { id: 1, name: 'Borrador' },
        nameClient: '',
        emailClient: '',
        serie: '',
        nif: '',
        total: 0.0,
        base: 0.0,
        iva: 0.0,
        discountPercent: 0.0,
        discount: 0.0,
        note: null,
        paymentType: { id: null, name: '' },
        route: { id: null, name: '' },
        recargoEqui: false,
        exentoIva: false,
        lines: [],
        ivasline: [],
        recargosline: [],
        street: { ...initElementStreet },
        isPay: null,
        payone: null,
        payoneDate: null,
        paytwo: null,
        paytwoDate: null,
        paythree: null,
        paythreeDate: null,
        payTotal: null,
        payLess: null,
        hiddenTax: true,
    }

    const estructureformline = [
        {
            type: 'input',
            name: 'name',
            colSpan: '30%',
            col: 4,
        },
        {
            type: 'input',
            name: 'amount',
            inputtype: 'number',
            step: 0.01,
            colSpan: '10%',
            setvalue: setValueAmout,
            col: 4,
        },
        {
            type: 'input',
            name: 'priceWithIva',
            inputtype: 'number',
            step: 0.01,
            setvalue: setValuePrice,
            tabeventnew: true,
            colSpan: '20%',
            col: 3,
        },
        {
            type: 'input',
            name: 'percentIva',
            inputtype: 'number',
            colSpan: '10%',
            setvalue: setValueIva,
            step: 0.1,
            col: 3,
        },
        {
            type: 'input',
            name: 'base',
            inputtype: 'number',
            step: 0.01,
            readonly: true,
            colSpan: '10%',
            col: 3,
        },
        {
            type: 'input',
            name: 'total',
            inputtype: 'number',
            step: 0.01,
            readonly: true,
            colSpan: '20%',
            col: 3,
        },
    ]

    const estructureform = [
        {
            type: 'title',
            name: 'titledata',
            col: 12,
        },
        {
            type: 'autocomplete',
            name: 'state.id',
            service: statebillService.combo,
            col: 2,
        },
        {
            type: 'input',
            name: 'serie',

            col: 2,
        },
        {
            type: 'input',
            name: 'number',
            readonly: true,

            col: 2,
        },
        {
            type: 'input',
            name: 'date',
            inputtype: 'date',

            col: 2,
        },
        {
            type: 'input',
            name: 'nif',

            col: 2,
        },
        {
            type: 'input',
            name: 'nameClient',
            col: 2,
        },
        {
            type: 'input',
            name: 'emailClient',
            col: 2,
        },
        ...estructureformStreet,
        {
            type: 'title',
            name: 'titlelineas',
            col: 12,
        },
        {
            type: 'table',
            name: 'lines',
            headers: [
                { label: 'Nombre', colSpan: '30%' },
                { label: 'Cantidad', colSpan: '10%' },
                { label: 'Precio', colSpan: '20%' },
                { label: '% Iva', colSpan: '10%' },
                { label: 'B. Imponible', colSpan: '10%' },
                { label: 'Total', colSpan: '20%' },
            ],
            initrow: { ...initline },
            fields: estructureformline,
            fieldId: 'id',
            setvalue: updateRows,

            col: 12,
        },
        {
            type: 'title',
            name: 'titletotal',
            col: 12,
        },
        {
            type: 'input',
            name: 'discountPercent',
            inputtype: 'number',
            step: 0.01,
            setvalue: updateDiscountTotal,

            col: 2,
        },
        {
            type: 'input',
            name: 'discount',
            inputtype: 'number',
            step: 0.01,
            readonly: true,
            col: 2,
        },
        {
            type: 'input',
            name: 'base',
            inputtype: 'number',
            step: 0.01,
            readonly: true,
            col: 2,
        },
        {
            type: 'input',
            name: 'iva',
            inputtype: 'number',
            step: 0.01,
            readonly: true,
            col: 1,
        },
        {
            type: 'input',
            name: 'total',
            inputtype: 'number',
            step: 0.01,
            readonly: true,
            col: 2,
        },
        {
            type: 'custom',
            name: 'ivasline',
            custom: fieldIvas,
            col: 12,
        },
    ]

    const { t, i18n } = useTranslation()

    const [{ bill, company, filter, reload, loading, expanded }, setState] =
        useState<GenerateBillCompanyParams>({
            bill: { ...initElement },
            company: -1,
            filter: { company: -1 },
            reload: false,
            loading: false,
            expanded: true,
        })

    const createEstructureFilter = [
        {
            type: 'autocomplete',
            name: 'company',
            service: companyService.combo,
            col: 3,
        },
    ]

    const cleanFilter = (filter) => {
        var update = {
            company: -1,
            lines: [],
            bill: { ...initElement },
        }

        setState((currentState) => ({ ...currentState, ...update }))
    }

    const searchFilter = (filter) => {
        var update = {
            loading: true,
            expanded: false,
        }

        setState((currentState) => ({ ...currentState, ...update }))

        billService.company(filter.company, (data, error) => {
            if (data && data.data) {
                var updateResult = {
                    loading: false,
                    bill: data.data,
                }

                setState((currentState) => ({
                    ...currentState,
                    ...updateResult,
                }))
            } else {
                var updateError = {
                    loading: false,
                    expanded: true,
                    bill: { ...initElement },
                }

                setState((currentState) => ({
                    ...currentState,
                    ...updateError,
                }))

                toast.error('No tiene alquileres pendientes', {
                    position: toast.POSITION.TOP_CENTER,
                })
            }
        })
    }

    const saveBill = (billToSave) => {
        billService.createcompany(billToSave, (data, error) => {
            window.location.href = '/bill/edit/' + data.data.id
        })
    }

    const cancelBill = (billToSave) => {
        var update = {
            company: -1,
            lines: [],
            bill: { ...initElement },
        }

        setState((currentState) => ({ ...currentState, ...update }))
    }

    return (
        <CrudEmpty i18n={'billcompany'} goback={true}>
            <Filter
                t={t}
                hiddenButton={true}
                i18n={'billcompany'}
                cleanFilter={cleanFilter}
                searchFilter={searchFilter}
                filter={filter}
                titlebutton={t('billcompany.titlebutton')}
                estructure={createEstructureFilter}
                expanded={expanded}
            />
            {loading && <div style={{ textAlign: 'center' }}></div>}
            {!loading && (
                <Grid container>
                    <Grid item sm={12} xs={12}>
                        <Card sx={{ padding: '2em' }}>
                            <Form
                                t={t}
                                i18n={'bill'}
                                objectform={bill}
                                fields={estructureform}
                                searchFilter={saveBill}
                                cleanFilter={cancelBill}
                                labelsave={'Guadar y generar'}
                                labelcancel={'Cancelar'}
                                reload={reload}
                            />
                        </Card>
                    </Grid>
                </Grid>
            )}
            <ToastContainer />
        </CrudEmpty>
    )
}
