import Dashboard from '../components/pages/dasboard/Dashboard';
import BaseService from './_base.service';

const URL = "/admin/car";

export const carService = {
    all,
    combo,
    page,
    pageFilter,
    one,
    create,
    edit,
    remove,
    removeLogic,
    login,
    recovery,
    logout,
    allFilterExcel,
    allFilterPdf,
    allFilterWord,
    opendoor,
    closedoor,
    lock,
    unlock,
    dashboard,
    mantenaiceStart,
    mantenaiceStop
};


function dashboard(callback) {
    return BaseService.get("/admin/dashboard", callback,false,false)
}

function all(callback) {
    return BaseService.get(URL+"/all/", callback,false,false)
}

function combo(callback) {
    return BaseService.get(URL+"/combo/", callback, false,false)
}

function page(page,size,callback) {
    return BaseService.get(URL+"/page/"+page+"/"+size, callback, false,false)
}

function pageFilter(page,size,filter,callback) {
    return BaseService.post(URL+"/page/"+page+"/"+size, filter, callback, false,false)
}

function one(id,callback) {
    return BaseService.get(URL + "/one/"+id, callback);
}

function opendoor(id,callback) {
    return BaseService.get(URL + "/opendoor/"+id, callback);
}

function closedoor(id,callback) {
    return BaseService.get(URL + "/closedoor/"+id, callback);
}


function lock(id,callback) {
    return BaseService.get(URL + "/lock/"+id, callback);
}

function unlock(id,callback) {
    return BaseService.get(URL + "/unlock/"+id, callback);
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post(URL, body, callback, true)
}

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put(URL+"/"+id, body, callback, true)
}

function remove(id,callback) {
    return BaseService.delete(URL+"/"+id, callback);
}

function removeLogic(id,callback) {
    return BaseService.delete(URL+"/"+id+"/logic", callback);
}

function login(user, callback) {
    let body = JSON.stringify(user)
    return BaseService.post("/login", body, callback, true)
}

function recovery(email, callback) {

    // localStorage.setItem('user', JSON.stringify(user));
    let body = JSON.stringify({ email })
    return BaseService.post("/recoverypass", body, callback, true)
}


function logout() {
    localStorage.removeItem('user');
}

function allFilterPdf(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/pdf", filter,"vehiculos.pdf", callback,)
}


function allFilterExcel(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/excel", filter,"vehiculos.xlsx", callback,)
}


function allFilterWord(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/word", filter,"vehiculos.doc", callback,)
}

function mantenaiceStart(id,callback) {
    return BaseService.get(URL + "/mantenaice/"+id+"/start", callback);
}

function mantenaiceStop(id,callback) {
    return BaseService.get(URL + "/mantenaice/"+id+"/stop", callback);
}
