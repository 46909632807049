import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import CrudTab from '../../crud/CrudTab'
import { bonouserUseService } from '../../../services'
import PrintIcon from '@mui/icons-material/Print'
import 'toasted-notes/src/styles.css'
import { useTranslation } from 'react-i18next'
import { ToastContainer, toast } from 'react-toastify'
import Grid from '@mui/material/Grid'
import Moment from 'react-moment'
import CrudTable from '../../crud/CrudTable'

type BonoUseParams = {
    id: string
}

export default function BonoUse(props) {
    var { id } = useParams<BonoUseParams>()

    const [filter, setFilter] = useState({
        user: id,
        from: null,
        to: null,
    })

    const { t } = useTranslation()

    useEffect(() => {}, [])

    useEffect(() => {
        setFilter({
            user: props.idclient,
            from: null,
            to: null,
        })
    }, [props.idclient])

    const paintvalue = {
        date: (value, object) => {
            if (value) {
                return <Moment date={value} format={'DD/MM/yyyy HH:mm:ss'} />
            } else {
                return <></>
            }
        },
        money: (value, object) => {
            if (value) {
                return <>{(+value).toFixed(2)}€</>
            } else {
                return <>0.00€</>
            }
        },
    }

    return (
        <>
            <CrudTable
                service={bonouserUseService}
                deleteaction={false}
                editable={false}
                notexport={true}
                hiddenew={true}
                filter={filter}
                i18n={'bonouseruse'}
                fieldstable={['motive', 'date', 'money']}
                haveother={false}
                selectable={false}
                hiddenFilter={true}
                paintvalue={paintvalue}
                rowsPerPage={40}
            />
            <ToastContainer />
        </>
    )
}
