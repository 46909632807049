import CrudTable from '../../crud/CrudTable'
import { userService as service, companyService } from '../../../services'

const initFilter = {
    name: null,
    email: null,
    phone: null,
    isClient: true,
    company: null,
    rol: 'COMPANY',
}

export default function Usercompany() {
    const createEstructureFilter = [
        {
            type: 'input',
            name: 'name',
            col: 4,
        },
        {
            type: 'input',
            name: 'lastname',
            col: 4,
        },
        {
            type: 'input',
            name: 'email',
            col: 4,
        },
        {
            type: 'input',
            name: 'phone',
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'company',
            col: 4,
            service: companyService.combo,
        },
    ]

    const paintCell = {
        validateState: (value, object) => {
            if (value == 'VALIDATE') {
                return <span style={{ color: 'green' }}>Validada</span>
            } else if (value == 'NONE') {
                return (
                    <span style={{ color: 'black' }}>
                        Documentación no enviada
                    </span>
                )
            } else if (value == 'REJECT') {
                return <span style={{ color: 'red' }}>Rechazada</span>
            } else if (value == 'PENDING') {
                return (
                    <span style={{ color: 'orange' }}>
                        Pendiente de validar
                    </span>
                )
            } else {
                return (
                    <span style={{ color: 'black' }}>
                        Documentación no enviada
                    </span>
                )
            }
        },
    }

    return (
        <CrudTable
            service={service}
            urledit={'/usercompany/edit/'}
            urlnew={'/usercompany/new/'}
            filter={initFilter}
            i18n={'usercompany'}
            paintCell={paintCell}
            order={true}
            fieldstable={[
                'id',
                'avatar',
                'email',
                'name',
                'lastname',
                'nif',
                'phone',
                'company.name',
            ]}
            orderColumns={{
                id: 'id',
                email: 'email',
                name: 'name',
                validateState: 'validateState',
            }}
            labels={{ id: '#' }}
            estructureForm={createEstructureFilter}
        />
    )
}
