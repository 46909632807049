import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../crud/CrudForm'
import {
    timetableService as service,
    companyService,
    userService,
} from '../../../services'
import { useTranslation } from 'react-i18next'

const initElement = {
    day: null,
    allDays: null,
    weekend: null,
    workingDays: null,
    hourStartMorning: null,
    endStarMorning: null,
    hourStartAfternoon: null,
    endStarAfternoon: null,
    user: { id: null, name: '' },
    company: { id: null, name: '' },
}

export default function FormCompanyUserTimeTable(props) {
    const [objectform, setObjectform] = React.useState({ ...initElement })
    type FormParms = {
        id: string
        company: string
        user: string
        userc: string
    }
    let { id, company, user, userc } = useParams<FormParms>()
    const [idRow, setIdrow] = useState(id ? Number(id) : 0)
    const { t, i18n } = useTranslation()

    var aux = {
        company: { id: company, name: '' },
        user: { id: user, name: '' },
    }
    const [object, setObject] = useState({
        ...initElement,
        ...aux,
    })

    const estructureform = [
        {
            type: 'autocomplete',
            name: 'company.id',
            service: companyService.combo,
            col: 4,
            readonly: true,
        },
        {
            type: 'autocomplete',
            name: 'user.id',
            service: userService.combo,
            col: 4,
            readonly: true,
        },
        {
            type: 'autocomplete',
            name: 'day',
            options: [
                { key: 2, name: 'Lunes' },
                { key: 3, name: 'Martes' },
                { key: 4, name: 'Miercoles' },
                { key: 5, name: 'Jueves' },
                { key: 6, name: 'Viernes' },
                { key: 7, name: 'Sabado' },
                { key: 1, name: 'Domingo' },
                { key: 8, name: 'Todo los días' },
                { key: 9, name: 'Fin de semana' },
                { key: 10, name: 'De lunes a viernes' },
            ],
            col: 4,
        },
        {
            type: 'input',
            name: 'hourStartMorning',
            inputtype: 'time',
            col: 4,
        },
        {
            type: 'input',
            name: 'endStarMorning',
            inputtype: 'time',
            col: 4,
        },
        {
            type: 'input',
            name: 'hourStartAfternoon',
            inputtype: 'time',
            col: 4,
        },
        {
            type: 'input',
            name: 'endStarAfternoon',
            inputtype: 'time',
            col: 4,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit['day'] = element.allDays
            ? 8
            : element.weekend
            ? 9
            : element.workingDays
            ? 10
            : element.day
        return objectedit
    }

    const isValid = function (object) {
        return null
    }

    return (
        <>
            <CrudForm
                objectform={{
                    ...object,
                }}
                estructureform={estructureform}
                i18n={'timetable'}
                urledit={
                    '/company/edit/' +
                    company +
                    '/timetable/user/' +
                    user +
                    '/' +
                    userc +
                    '/edit/'
                }
                urlCancel={
                    '/company/edit/' + company + '/usertocompany/edit/' + userc
                }
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
            />
        </>
    )
}
