import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
    userService as service,
    companyService,
    moneymovementsService,
} from '../../../services'
import { useTranslation } from 'react-i18next'
import TabRental from './TabRental'
import TabShare from './TabShare'
import TabMoney from './TabMoney'
import TabDocument from './TabDocument'
import { Box, Button, Modal, TextField } from '@mui/material'
import Form from '../../form/Form'
import CrudFormStateTabs from '../../crud/CrudFormStateTabs'

const initElement = {
    email: '',
    pass: '',
    name: '',
    lastname: '',
    isClient: true,
    rol: { code: 'COMPANY', name: 'COMPANY' },
    enable: true,
    picture: null,
    acceptnews: false,
    acceptTerms: null,
    acceptPoli: null,
    nif: null,
    alias: null,
    street: null,
    city: null,
    state: null,
    postalcode: null,
    dni_front: null,
    dni_back: null,
    license_front: null,
    license_back: null,
    credit_number: null,
    credit_code: null,
    credit_month: null,
    credit_year: null,
    avatar: null,
    dni_day: null,
    dni_month: null,
    dni_year: null,
    license_day: null,
    license_month: null,
    license_year: null,
    bank_account: null,
    redsys_token: null,
    stripe_id: null,
    money: null,
    recarge: false,
    moneyRecarge: null,
    company: { id: null, name: null },
    validate: false,
}

type FormUserbusinessParms = {
    id: string
}

export default function FormUsercompany() {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        maxWidth: '80%',
        bgcolor: 'white',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }

    const [objectform, setObjectform] = React.useState({ ...initElement })
    let { id } = useParams<FormUserbusinessParms>()
    const [idRow, setIdrow] = useState(id ? Number(id) : 0)
    const { t, i18n } = useTranslation()

    const [openMoney, setOpenMoney] = useState(false)
    const [openValidate, setOpenValidate] = useState(false)
    const [openReject, setOpenReject] = useState(false)
    const [reload, setReload] = useState(false)
    const [motive, setMotive] = useState('')

    const [money, setMoney] = useState({
        user: { id: id },
        concept: null,
        money: null,
    })

    const estructureform = [
        {
            type: 'input',
            name: 'email',
            readonly: idRow > 0 ? true : false,
            col: 4,
        },
        {
            type: 'input',
            name: 'pass',
            col: 4,
            inputtype: 'password',
        },
        {
            type: 'input',
            name: 'name',
            col: 4,
        },
        {
            type: 'input',
            name: 'lastname',
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'company.id',
            col: 4,
            service: companyService.combo,
        },
        {
            type: 'title',
            name: 'titledir',
            col: 12,
        },
        {
            type: 'input',
            name: 'street',
            col: 8,
        },
        {
            type: 'input',
            name: 'city',
            col: 4,
        },
        {
            type: 'input',
            name: 'postalcode',
            col: 4,
        },
        {
            type: 'input',
            name: 'state',
            col: 4,
        },
    ]

    const createEstructureMoney = [
        {
            type: 'input',
            name: 'concept',
            col: 6,
        },
        {
            type: 'input',
            name: 'money',
            inputtype: 'number',
            col: 6,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit = { ...objectedit, ...element }

        return objectedit
    }

    const isValid = function (object) {
        if (!object['name']) {
            return t('administrator.error.name')
        }
        if (!object['email']) {
            return t('administrator.error.email')
        }
        if (
            !object['rol'] ||
            !object['rol']['code'] ||
            !object['rol']['code'] == null
        ) {
            return t('administrator.error.rol')
        }

        return null
    }

    const tabRental = (id) => {
        return <TabRental idclient={id} />
    }

    const tabShare = (id) => {
        return <TabShare idclient={id} />
    }

    const tabMoney = (id) => {
        return <TabMoney idclient={id} />
    }

    const tabDocs = (id) => {
        return <TabDocument idclient={id} />
    }

    const handleCloseMoney = () => {
        setOpenMoney(false)
        setMoney({
            user: { id: id },
            concept: null,
            money: null,
        })
    }

    const saveMoney = (object) => {
        if (object['concept'] && object['concept'] !== '' && object['money']) {
            moneymovementsService.create(object, (data, error) => {
                if (data && data.data) {
                    handleCloseMoney()
                    window.location.reload()
                }
            })
        }
    }

    const openModalMoney = () => {
        setOpenMoney(true)
    }

    const handleCloseValidate = () => {
        setOpenValidate(false)
    }

    const handleCloseReject = () => {
        setOpenReject(false)
    }

    const validateUser = () => {
        service.validate(id, (data, error) => {
            if (data && data.data) {
                window.location.reload()
            }
        })
    }

    const rejectUser = () => {
        service.reject(id, { motive: motive }, (data, error) => {
            if (data && data.data) {
                window.location.reload()
            }
        })
    }

    const moreActions = (getObject) => {
        var isGenerate = id && getObject && getObject()
        var isValid =
            id &&
            getObject &&
            getObject() &&
            getObject().validateState == 'VALIDATE'
        var isReject =
            id &&
            getObject &&
            getObject() &&
            getObject().validateState == 'REJECT'
        return (
            <>
                {isGenerate && (
                    <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: '30px' }}
                        onClick={openModalMoney}
                    >
                        {' '}
                        Añadir movimiento monedero
                    </Button>
                )}
                {isGenerate && !isValid && (
                    <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: '30px' }}
                        onClick={() => setOpenValidate(true)}
                    >
                        {' '}
                        Validar
                    </Button>
                )}
                {isGenerate && !isReject && (
                    <Button
                        variant="outlined"
                        color="warning"
                        style={{ marginLeft: '30px' }}
                        onClick={() => setOpenReject(true)}
                    >
                        {' '}
                        Rechazar
                    </Button>
                )}
            </>
        )
    }

    return (
        <>
            <CrudFormStateTabs
                objectform={{ ...initElement }}
                estructureform={estructureform}
                i18n={'usercompany'}
                urledit={'/usercompany/edit/'}
                urlCancel={'/usercompany'}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
                reload={reload}
                tabs={[
                    { title: 'usercompany.tab.rent', method: tabRental },
                    { title: 'usercompany.tab.share', method: tabShare },
                    { title: 'usercompany.tab.doc', method: tabDocs },
                ]}
            />
        </>
    )
}
