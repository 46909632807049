import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import CrudTab from '../../crud/CrudTab'
import { disabledaysService as service } from '../../../services'
import PrintIcon from '@mui/icons-material/Print'
import 'toasted-notes/src/styles.css'
import { useTranslation } from 'react-i18next'
import { ToastContainer, toast } from 'react-toastify'
import Grid from '@mui/material/Grid'

const moment = require('moment')

export default function TabDisabledaysUser(props) {
    const [filter, setFilter] = useState({
        car: null,
        company: props.idcompany,
        cartype: null,
        user: props.iduser,
        userc: props.userc,
    })

    const { t } = useTranslation()

    useEffect(() => {}, [])

    useEffect(() => {
        setFilter({
            car: null,
            company: props.idcompany,
            cartype: null,
            user: props.iduser,
            userc: props.userc,
        })
    }, [props.idcompany, props.iduser, props.userc])

    const paintCell = {
        day: (value, object) => {
            if (value) {
                try {
                    return (
                        <span>
                            {moment(Date.parse(value)).format('DD/MM/YYYY')}
                        </span>
                    )
                } catch (e) {
                    return <span> -- </span>
                }
            } else {
                return <span> -- </span>
            }
        },
    }

    return (
        <>
            <CrudTab
                hiddenFilter={true}
                service={service}
                newbtn={true}
                titlebutton={'Nueva día no laborable'}
                urlnew={
                    '/company/edit/' +
                    props.idcompany +
                    '/disableday/user/' +
                    props.iduser +
                    '/' +
                    props.userc +
                    '/new/'
                }
                urledit={
                    '/company/edit/' +
                    props.idcompany +
                    '/disabledayuser/' +
                    props.iduser +
                    '/' +
                    props.userc +
                    '/edit/'
                }
                filter={filter}
                i18n={'disableday'}
                fieldstable={['day', 'name']}
                paintCell={paintCell}
                haveother={false}
                deleteaction={true}
                selectable={false}
            />
            <ToastContainer />
        </>
    )
}
