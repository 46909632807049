import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../crud/CrudForm'
import {
    usercompaniesService as service,
    companyService,
    userService,
} from '../../../services'
import { useTranslation } from 'react-i18next'
import TabTimetableUser from './TabTimetableUser'
import { Grid } from '@mui/material'
import TabDisabledaysUser from './TabDisabledaysUser'
import CrudFormStateTabs from '../../crud/CrudFormStateTabs'

const initElement = {
    nifUser: null,
    acceptByCompany: null,
    dateAcceptByCompany: null,
    acceptByUser: null,
    dateAcceptByUser: null,
    dateEnd: null,
    user: { id: null, name: '' },
    company: { id: null, name: '' },
}

export default function FormCompanyToUsers(props) {
    const [objectform, setObjectform] = React.useState({ ...initElement })
    type FormParms = {
        id: string
        company: string
    }
    let { id, company } = useParams<FormParms>()
    const [idRow, setIdrow] = useState(id ? Number(id) : 0)
    const [iduser, setIduser] = useState<any>(null)
    const { t, i18n } = useTranslation()
    const [object, setObject] = useState({
        ...initElement,
        ['company']: { id: company, name: '' },
    })

    const estructureform = [
        {
            type: 'autocomplete',
            name: 'company.id',
            service: companyService.combo,
            col: 4,
            readonly: true,
        },
        {
            type: 'input',
            name: 'nifUser',
            col: 4,
        },
        {
            type: 'input',
            name: 'maxMoneyMonthShare',
            col: 4,
            inputtype: 'number',
        },
        {
            type: 'autocomplete',
            name: 'user.id',
            service: userService.combo,
            col: 4,
            readonly: true,
        },
        {
            type: 'checkbox',
            name: 'acceptByCompany',
            col: 4,
            readonly: true,
        },
        {
            type: 'input',
            name: 'dateAcceptByCompany',
            inputtype: 'date',
            col: 4,
            readonly: true,
        },
        {
            type: 'checkbox',
            name: 'acceptByUser',
            col: 4,
            readonly: true,
        },
        {
            type: 'input',
            name: 'dateAcceptByUser',
            inputtype: 'date',
            col: 4,
            readonly: true,
        },
        {
            type: 'input',
            name: 'day',
            inputtype: 'date',
            col: 4,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        //objectedit = element
        objectedit['nifUser'] = element['nifUser']
        objectedit['acceptByCompany'] = element['acceptByCompany']
        objectedit['dateAcceptByCompany'] = element['dateAcceptByCompany']
        objectedit['acceptByUser'] = element['acceptByUser']
        objectedit['dateAcceptByUser'] = element['dateAcceptByUser']
        objectedit['dateEnd'] = element['dateEnd']
        objectedit['user'] =
            element['user'] != null ? element['user'] : { id: null, name: '' }
        objectedit['company'] = element['company']

        return objectedit
    }

    const isValid = function (object) {
        return null
    }

    const tabTabTimetable = (id, objectform) => {
        if (
            objectform != null &&
            objectform['user'] != null &&
            objectform['user']['id'] != null
        ) {
            return (
                <TabTimetableUser
                    idcompany={company}
                    iduser={objectform['user']['id']}
                    userc={id}
                />
            )
        } else {
            return (
                <Grid container>
                    <Grid xs={12}>
                        <p>El usuario aun no se ha dado de alta en Mobify</p>
                    </Grid>
                </Grid>
            )
        }
    }

    const tabTabDisabledays = (id, objectform) => {
        if (
            objectform != null &&
            objectform['user'] != null &&
            objectform['user']['id'] != null
        ) {
            return (
                <TabDisabledaysUser
                    idcompany={company}
                    iduser={objectform['user']['id']}
                    userc={id}
                />
            )
        } else {
            return (
                <Grid container>
                    <Grid xs={12}>
                        <p>El usuario aun no se ha dado de alta en Mobify</p>
                    </Grid>
                </Grid>
            )
        }
    }

    return (
        <>
            <CrudFormStateTabs
                objectform={{
                    ...object,
                }}
                estructureform={estructureform}
                i18n={'usertocompany'}
                urledit={'/company/edit/' + company + '/usertocompany/edit/'}
                urlCancel={'/company/edit/' + company}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
                tabs={[
                    {
                        title: 'company.tabs.timetable',
                        method: tabTabTimetable,
                    },
                    /*{
                        title: 'company.tabs.user',
                        method: tabTabUsers,
                    },*/
                ]}
            />
        </>
    )
}
